import React, { useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    Button,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
    SelectChangeEvent,
    FormControl,
    Select,
    MenuItem,
    Stack,
    FormHelperText,
    styled,
    Box,
    IconButton,
    Avatar,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';

import { useSelector, useStore } from 'react-redux';
import { change_is_loading } from '../../../../reducers/Actions';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { HOST_BE } from '../../../../common/Common';
import axios from 'axios';
import { toastSuccess, toastWarning } from '../../../../untils/Logic';
import { useTranslation } from 'react-i18next';
import { ReducerProps } from '../../../../reducers/ReducersProps';
import QuillNoSSRWrapper, { Quill } from 'react-quill';
import ReactQuill from 'react-quill';

const Input = styled('input')({
    display: 'none',
});

interface CreateProductDialogProps {
    onClose: () => void;
    open: boolean;
    categories: Array<any>;
    materials: Array<any>;
    styles: Array<any>;
    origins: Array<any>;
    brands: Array<any>;
    onUpdate: () => void;
}

const CreateProductDialog: React.FC<CreateProductDialogProps> = (props) => {
    const { t } = useTranslation();
    const store = useStore();
    const user = useSelector((state: ReducerProps) => state.user);
    const { onClose, open, categories, materials, styles, origins, brands, onUpdate } = props;

    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState(0);
    const [productDescribe, setProductDescribe] = useState('');
    const [selectImage, setSelectImage] = useState<File | null>(null);
    //select
    const [categoryIdSelect, setCategoryIdSelected] = useState('');
    const [materialIdSelect, setMaterialIdSelected] = useState('');
    const [styleIdSelect, setStyleIdSelected] = useState('');
    const [originIdSelect, setOriginIdSelected] = useState('');
    const [brandIdSelect, setBrandIdSelected] = useState('');
    //option
    const [colorInput, setColorInput] = useState<string>(''); // Trường nhập màu sắc
    const [colors, setColors] = useState<string[]>([]); // Danh sách màu sắc
    const [sizeInput, setSizeInput] = useState<string>(''); // Trường nhập kích thước
    const [sizes, setSizes] = useState<string[]>([]); // Danh sách kích thước
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const [prices, setPrices] = useState<{ [key: string]: number }>({});
    const [uploadedImages, setUploadedImages] = useState<{ [key: string]: File[] }>({});

    const handleClose = () => {
        onClose();
    };
    const categoryList = categories.filter((category) => !(category.categoryIdClIST.length > 0));
    //product select handle
    const handleChangeSelect = (event: SelectChangeEvent, setter: React.Dispatch<React.SetStateAction<string>>) => {
        setter(event.target.value);
    };

    const handleCreateProduct = async () => {
        
        store.dispatch(change_is_loading(true));
        const formData = new FormData();
        formData.append('name', productName);
        formData.append('price', productPrice.toString());
        formData.append('describe', productDescribe);
        formData.append('categoryId', categoryIdSelect);
        formData.append('materialId', materialIdSelect);
        formData.append('styleId', styleIdSelect);
        formData.append('originId', originIdSelect);
        formData.append('brandId', brandIdSelect);
        formData.append('shopId', user.shopId);
        formData.append('options', JSON.stringify({ sizes, colors }));
        console.log(productDescribe);
        console.log(categoryIdSelect, materialIdSelect, styleIdSelect);
        console.log(uploadedImages, quantities, prices);

        if (selectImage) {
            const uniqueFilename = `image_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
            const imageBlob = await fetch(URL.createObjectURL(selectImage)).then((response) => response.blob());
            formData.append('file', imageBlob, uniqueFilename);
        }
        try {
            const res = await axios.post(`${HOST_BE}/shop/add/product`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (res.data.message === 'Success') {
                handleCreateProductDetail(res.data.product.id);
                toastSuccess(t('toast.CreateSuccess'));
            }
            else{
                store.dispatch(change_is_loading(false));
            }
        } catch (error) {
            console.error('Failed to add product:', error);
        } finally {
            handleClose();
        }
    };
    const handleCreateProductDetail = async (productId: string) => {
        const combinations = createCombinations();

        for (const combo of combinations) {
            const formData = new FormData();
            formData.append('name', productName);
            formData.append('price', prices[`${combo.color}-${combo.size}`]?.toString() || productPrice.toString());
            formData.append('quantity', quantities[`${combo.color}-${combo.size}`]?.toString() || '0');
            formData.append('productId', productId);
            formData.append('option1', combo.size);
            formData.append('option2', combo.color);

            // Upload images
            const images = uploadedImages[`${combo.color}-${combo.size}`] || [];
            for (const image of images) {
                const uniqueFilename = `image_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
                const imageBlob = await fetch(URL.createObjectURL(image)).then((response) => response.blob());
                formData.append('files', imageBlob, uniqueFilename);
            }

            try {
                const res = await axios.post(`${HOST_BE}/shop/add/productDetail`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (res.data.message === 'Success') {
                }
            } catch (error) {
                console.error('Failed to add product detail:', error);
            }
        }
        onUpdate();
        store.dispatch(change_is_loading(false));
    };

    //Add options
    const handleAddColor = () => {
        if (colorInput.trim()) {
            setColors([...colors, colorInput]);
            setColorInput('');
        }
    };

    const handleRemoveColor = (index: number) => {
        const newColors = colors.filter((_, i) => i !== index);
        setColors(newColors);
    };

    const handleAddSize = () => {
        if (sizeInput.trim()) {
            setSizes([...sizes, sizeInput]);
            setSizeInput('');
        }
    };

    const handleRemoveSize = (index: number) => {
        const newSizes = sizes.filter((_, i) => i !== index);
        setSizes(newSizes);
    };


    const handlePriceChange = (color: string, size: string, newPrice: number) => {
        const key = `${color}-${size}`;
        setPrices((prev) => ({
            ...prev,
            [key]: newPrice,
        }));
    };

    const createCombinations = (): { color: string; size: string }[] => {
        const combinations: { size: string; color: string }[] = [];
        sizes.forEach((size) => {
            colors.forEach((color) => {
                combinations.push({ size, color });
            });
        });
        return combinations;
    };
    const handleUploadImages = (color: string, size: string, files: FileList | null) => {
        if (files) {
            const uniqueKey = `${color}-${size}`;
            const newImages = Array.from(files);
            setUploadedImages((prev) => ({
                ...prev,
                [uniqueKey]: [...(prev[uniqueKey] || []), ...newImages],
            }));
        }
    };
    const handleRemoveImage = (color: string, size: string, imgIndex: number) => {
        const key = `${color}-${size}`;
        setUploadedImages((prev) => {
            const updatedImages = [...(prev[key] || [])];
            updatedImages.splice(imgIndex, 1);
            return {
                ...prev,
                [key]: updatedImages,
            };
        });
    };
    const resetFields = () => {
        setSelectImage(null);
        setCategoryIdSelected('');
        setMaterialIdSelected('');
        setStyleIdSelected('');
        setOriginIdSelected('');
        setBrandIdSelected('');
        setColorInput('');
        setColors([]);
        setSizeInput('');
        setSizes([]);
    };

    useEffect(() => {
        if (open) {
            resetFields();
        }
    }, [open]);
    const reactQuillRef = useRef<ReactQuill>(null);
    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ size: [] }],
                [{ font: [] }],
                ['image', 'video'],
                ['clean'],
            ],
        },
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'image',
        'video',
    ];
    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={open}>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());

                            if (colors.length === 0 || sizes.length === 0) {
                                toastWarning(t('toast.CreateProductCondition'));
                                return;
                            }
                            if (!selectImage) {
                                toastWarning(t('toast.NeedProductImage'));
                                return;
                            }

                            await handleCreateProduct();
                        },
                    }}
                >
                    <DialogTitle>{t('product.ShopManagement.CreateProduct')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 1 }}>
                            {t('product.ShopManagement.CreateProductForm')}
                        </DialogContentText>
                        <Box>
                            <React.Fragment>
                                <Card>
                                    <CardHeader title={t('product.ShopManagement.CreateProduct')}></CardHeader>
                                    <Divider />
                                    <CardContent>
                                        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                                            <TextField
                                                required
                                                margin="dense"
                                                id="name"
                                                name="name"
                                                label={t('product.Name')}
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mb: 1 }}
                                                value={productName}
                                                onChange={(e) => {
                                                    setProductName(e.target.value);
                                                }}
                                            />
                                            <TextField
                                                error={!(productPrice >= 0)}
                                                helperText={productPrice < 0 ? t('product.NonNegativePrice') : ''}
                                                required
                                                margin="dense"
                                                id="price"
                                                name="price"
                                                label={t('product.Price')}
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mb: 1, maxWidth: 150 }}
                                                value={productPrice}
                                                onChange={(e) => {
                                                    setProductPrice(Number(e.target.value));
                                                }}
                                            />
                                        </Stack>
                                        <QuillNoSSRWrapper
                                            ref={reactQuillRef}
                                            style={{ maxHeight: 400, height: 400 }}
                                            theme="snow"
                                            value={productDescribe}
                                            modules={modules}
                                            onChange={(value) => {
                                                setProductDescribe(value);
                                            }}
                                            formats={formats}
                                        />
                                        <Stack direction="row" spacing={1} sx={{ mb: 1, mt: 5 }}>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
                                                <FormHelperText>
                                                    {t('product.ShopManagement.CategorySelect')}
                                                </FormHelperText>
                                                <Select
                                                    id="select-parent-cate-lvl1"
                                                    value={categoryIdSelect}
                                                    onChange={(e) => handleChangeSelect(e, setCategoryIdSelected)}
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="">
                                                        <em>{t('orther.None')}</em>
                                                    </MenuItem>
                                                    {categoryList.map((category) => (
                                                        <MenuItem value={category.id}>{category.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                                                <FormHelperText>
                                                    {t('product.ShopManagement.MaterialSelect')}
                                                </FormHelperText>
                                                <Select
                                                    id="select-material"
                                                    value={materialIdSelect}
                                                    onChange={(e) => handleChangeSelect(e, setMaterialIdSelected)}
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="">
                                                        <em>{t('orther.None')}</em>
                                                    </MenuItem>
                                                    {materials.map((material) => (
                                                        <MenuItem value={material.id}>{material.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                                                <FormHelperText>
                                                    {t('product.ShopManagement.StyleSelect')}
                                                </FormHelperText>
                                                <Select
                                                    id="select-style"
                                                    value={styleIdSelect}
                                                    onChange={(e) => handleChangeSelect(e, setStyleIdSelected)}
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="">
                                                        <em>{t('orther.None')}</em>
                                                    </MenuItem>
                                                    {styles.map((style) => (
                                                        <MenuItem value={style.id}>{style.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                                                <FormHelperText>
                                                    {t('product.ShopManagement.OriginSelect')}
                                                </FormHelperText>
                                                <Select
                                                    id="select-origin"
                                                    value={originIdSelect}
                                                    onChange={(e) => handleChangeSelect(e, setOriginIdSelected)}
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="">
                                                        <em>{t('orther.None')}</em>
                                                    </MenuItem>
                                                    {origins.map((origin) => (
                                                        <MenuItem value={origin.id}>{origin.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 180 }}>
                                                <FormHelperText>
                                                    {t('product.ShopManagement.BrandSelect')}
                                                </FormHelperText>
                                                <Select
                                                    id="select-brand"
                                                    value={brandIdSelect}
                                                    onChange={(e) => handleChangeSelect(e, setBrandIdSelected)}
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="">
                                                        <em>{t('orther.None')}</em>
                                                    </MenuItem>
                                                    {brands.map((brand) => (
                                                        <MenuItem value={brand.id}>{brand.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                        {/* Thêm tùy chọn màu sắc */}
                                        <Typography variant="h6">{t('product.Color')}</Typography>
                                        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                                            <TextField
                                                margin="dense"
                                                label={t('product.EnterColor')}
                                                variant="outlined"
                                                value={colorInput}
                                                onChange={(e) => setColorInput(e.target.value)}
                                                fullWidth
                                            />
                                            <IconButton color="primary" onClick={handleAddColor}>
                                                <AddRoundedIcon></AddRoundedIcon>
                                            </IconButton>
                                        </Stack>

                                        {/* Hiển thị danh sách màu sắc đã thêm */}
                                        <Typography variant="subtitle1">{t('product.ColorList')}:</Typography>
                                        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                                            {colors.map((color, index) => (
                                                <Chip
                                                    key={index}
                                                    label={color}
                                                    onDelete={() => handleRemoveColor(index)}
                                                    sx={{ margin: '2px' }}
                                                />
                                            ))}
                                        </Stack>
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                        {/* Thêm tùy chọn kích thước */}
                                        <Typography variant="h6">{t('product.Size')}</Typography>
                                        <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                                            <TextField
                                                margin="dense"
                                                label={t('product.EnterSize')}
                                                variant="outlined"
                                                value={sizeInput}
                                                onChange={(e) => setSizeInput(e.target.value)}
                                                fullWidth
                                            />
                                            <IconButton color="primary" onClick={handleAddSize}>
                                                <AddRoundedIcon></AddRoundedIcon>
                                            </IconButton>
                                        </Stack>

                                        {/* Hiển thị danh sách kích thước đã thêm */}
                                        <Typography variant="subtitle1">{t('product.SizeList')}:</Typography>
                                        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                                            {sizes.map((size, index) => (
                                                <Chip
                                                    key={index}
                                                    label={size}
                                                    onDelete={() => handleRemoveSize(index)}
                                                    sx={{ margin: '2px' }}
                                                />
                                            ))}
                                        </Stack>
                                        <Divider sx={{ mb: 1, mt: 1 }} />
                                        <Typography variant="h6">{t('product.Image')}</Typography>
                                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <Avatar
                                                variant="square"
                                                sx={{ minWidth: 200, minHeight: 200 }}
                                                src={selectImage ? URL.createObjectURL(selectImage) : undefined}
                                            />
                                            <label
                                                htmlFor="image"
                                                style={{ position: 'absolute', bottom: '8px', right: '8px' }}
                                            >
                                                <IconButton component="span" color="primary">
                                                    <UploadTwoToneIcon />
                                                </IconButton>
                                            </label>
                                            <Input
                                                id="image"
                                                name="image"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }} // Ẩn input file
                                                onChange={(e: any) => {
                                                    const file = e.target.files[0];
                                                    console.log(file);
                                                    if (
                                                        file &&
                                                        (file.type === 'image/png' ||
                                                            file.type === 'image/jpeg' ||
                                                            file.type === 'image/webp')
                                                    ) {
                                                        setSelectImage(file);
                                                    } else {
                                                        toastWarning('File type is not allowed');
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card>
                                    <CardHeader title={t('product.ProductOptionList')}></CardHeader>
                                    <Divider />
                                    <CardContent>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Màu sắc</TableCell>
                                                        <TableCell>Kích thước</TableCell>
                                                        <TableCell>Giá</TableCell>
                                                        <TableCell>Số lượng</TableCell>
                                                        <TableCell>Tải lên Ảnh</TableCell>
                                                        <TableCell>Ảnh đã tải lên</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {createCombinations().map((combo, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{combo.color}</TableCell>
                                                            <TableCell>{combo.size}</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    defaultValue={productPrice}
                                                                    onChange={(e) =>
                                                                        handlePriceChange(
                                                                            combo.color,
                                                                            combo.size,
                                                                            Number(e.target.value),
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={
                                                                        quantities[`${combo.color}-${combo.size}`] || 0
                                                                    }
                                                                    onChange={(e) =>
                                                                        setQuantities({
                                                                            ...quantities,
                                                                            [`${combo.color}-${combo.size}`]: Number(
                                                                                e.target.value,
                                                                            ),
                                                                        })
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    id={`upload-${combo.color}-${combo.size}`}
                                                                    onChange={(e) =>
                                                                        handleUploadImages(
                                                                            combo.color,
                                                                            combo.size,
                                                                            e.target.files,
                                                                        )
                                                                    }
                                                                />
                                                                <label htmlFor={`upload-${combo.color}-${combo.size}`}>
                                                                    <IconButton component="span" color="primary">
                                                                        <UploadTwoToneIcon />
                                                                    </IconButton>
                                                                </label>
                                                            </TableCell>
                                                            <TableCell>
                                                                {uploadedImages[`${combo.color}-${combo.size}`]?.map(
                                                                    (image, imgIndex) => (
                                                                        <div
                                                                            key={imgIndex}
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={URL.createObjectURL(image)}
                                                                                alt={`img-${imgIndex}`}
                                                                                style={{
                                                                                    width: '50px',
                                                                                    height: '50px',
                                                                                    marginRight: '10px',
                                                                                }}
                                                                            />
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleRemoveImage(
                                                                                        combo.color,
                                                                                        combo.size,
                                                                                        imgIndex,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                            </React.Fragment>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('action.Cancel')}</Button>
                        <Button type="submit">{t('action.Confirm')}</Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </React.Fragment>
    );
};
export default CreateProductDialog;
