import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { typeLng } from '../common/Common';
export const initI18n = (lng: string) => {
    i18n.use(initReactI18next).init({
        resources: {
            vn: {
                translation: {
                    auth: {
                        Login: 'Đăng nhập',
                        OrLoginWith: 'Hoặc đăng nhập bằng',
                        Register: 'Đăng ký',
                        Phone: 'Số điện thoại',
                        Password: 'Mật khẩu',
                        'Please enter complete information': 'Vui lòng nhập đủ thông tin',
                        'Account is incorrect': 'Tài khoản mật khẩu không đúng',
                        'Enter OTP': 'Nhập OTP',
                        Submit: 'Gửi',
                        Cancel: 'Huỷ',
                        'Verify failed': 'Xác thực thất bại',
                        'Verify success': 'Xác thực thành công',
                        'Can not send OTP': 'Không thể gửi OTP',
                        'Send OTP successfully': 'Đã gửi OTP',
                        'Forget password': 'Quên mật khẩu',
                        'Enter phone': 'Nhập số điện thoại',
                        'Phone non exist': 'Số điện thoại không tồn tại',
                        Success: 'Thành công',
                        'Can not change password': 'Không thể thay đổi mật khẩu',
                        Change: 'Thay đổi',
                        'Re-password': 'Nhập lại mật khẩu',
                        'Password must contain letters, numbers, one capital letter, one special character and must be 10 characters or more':
                            'Mật khẩu phải bao gồm chữ ,số ,một chữ cái hoa , một kí tự đặc biệt và phải từ 10 kí tự trở lên',
                        'Password is no strong': 'Mật khẩu yếu',
                        'Password and Re-password do not match': 'Không trùng khớp',
                        'Hello, Friend': 'Chào bạn',
                        'Become a member to receive many attractive offers':
                            'Trở thành thành viên để nhận được nhiều ưu đãi hấp dẫn',
                        'Welcome Back': 'Chào mừng trở lại',
                        'Account is inActive': 'Tài khoản không hoạt động',
                        'To login please enter your personal information':
                            'Để đăng nhập vui lòng nhập thông tin cá nhân của bạn',
                        'Account have already exist': 'Tài khoản đã tồn tại',
                        'Enter Email': 'Nhập Email',
                        'Can not find user': 'Không tìm thấy người dùng',
                        Back: 'Quay lại',
                        IncorrectPassword: 'Mật khẩu không đúng',
                    },
                    homepage: {
                        'Free returns within 30 days': 'Đổi trả miễn phí trong vòng 30 ngày',
                        Exit: 'Thoát',
                        'Hot key': 'Từ khoá hot',
                        'Product suggestions': 'Gợi ý sản phẩm',
                        'New Product': 'Sản Phẩm Mới',
                        Sale: 'Giảm Giá',
                        Category: 'Danh Mục',
                        'Men Fashion': 'Thời Trang Nam',
                        'Women Fashion': 'Thời Trang Nữ',
                    },
                    user: {
                        Profile: 'Thông Tin',
                        Orders: 'Đơn Hàng',
                        Logout: 'Đăng Xuất',
                        Setting: 'Cài Đặt',
                        'Info Profile': 'Thông tin cá nhân',
                        Name: 'Tên',
                        Birthday: 'Ngày sinh',
                        Sex: 'Giới tính',
                        Male: 'Nam',
                        Female: 'Nữ',
                        Phone: 'Số điện thoại',
                        Address: 'Địa chỉ',
                        Secure: 'Bảo mật',
                        Notification: 'Thông báo',
                        Shop: 'Cửa hàng',
                        AllAddress: 'Tất cả địa chỉ',
                        CreateAddress: 'Tạo địa chỉ mới',
                        City: 'Thành phố',
                        District: 'Quận/huyện',
                        Ward: 'Phường/Xã',
                        ApartmentNumber: 'Số nhà',
                        Save: 'Lưu',
                        Default: 'Mặc định',
                        EditAddress: 'Chỉnh sửa địa chỉ',
                        InfoNotSave: 'Có dữ liệu chưa lưu',
                        InfoShop: 'Thông tin Shop',
                        DefaultAddress: 'Địa chỉ mặc định',
                        Wallet: 'Ví',
                        RegisterWallet: 'Đăng kí ví',
                        Code: 'Mã',
                        Describe: 'Mô tả',
                        Time: 'Thời gian',
                        Value: 'Giá trị',
                        Balance: 'Tổng số dư',
                        Deposite: 'Nạp',
                        Withdraw: 'Rút',
                        MethodBank: 'Phương thức thanh toán',
                        NameBank: 'Tên ngân hàng',
                        NumberAccount: 'Số tài khoản',
                        NameAccount: 'Tên tài khoản',
                        EnterNumber: 'Nhập số tiền',
                        History: 'Lịch sử giao dịch',
                        Pending: 'Đang xử lý',
                        'There is a transaction being processed': 'Có một giao dịch đang được xử lý',
                        NotEnoughBalance: 'Không đủ số dư',
                        PleaseEnterNumber: 'Vui lòng nhập số lượng',
                        YourVoucher: 'Voucher',
                    },
                    shop: {
                        Name: 'Tên Shop',
                        Address: 'Địa chỉ Shop',
                        Phone: 'Số điện thoại',
                        ShopNameAlreadyExits: 'Tên Shop đã tồn tại',
                        Followed: 'Lượt follow',
                        TopProduct: 'Sản phẩm bán chạy',
                        StartDay: 'Bắt đầu từ',
                        Condition: 'Điều kiện',
                        Expery: 'Ngày hết hạn',
                    },
                    product: {
                        Id: 'Mã sản phẩm',
                        Color: 'Màu sắc',
                        Size: 'Kích thước',
                        ColorList: 'Danh sách màu sắc',
                        SizeList: 'Danh sách kích thước',
                        ProductOptionList: 'Danh sách tùy chọn sản phẩm',
                        NumberSold: 'Đã bán',
                        QuantityProduct: 'Số lượng',
                        Quantity: 'Còn lại',
                        AddToCart: 'Thêm Vào Giỏ',
                        BuyNow: 'Mua Ngay',
                        SizeTip: 'Gợi ý size phù hợp',
                        Describe: 'Mô tả',
                        Info: 'Thông tin chi tiết',
                        PercentDisCountTop: 'Giảm giá cao nhất',
                        PercentDisCount: 'Giảm giá',
                        Category: 'Danh mục',
                        Material: 'Chất liệu',
                        Brand: 'Thương hiệu',
                        Styles: 'Kiểu dáng',
                        Origin: 'Xuất xứ',
                        Shop: 'Cửa hàng',
                        Sold: 'Đã bán',
                        Product: 'Sản phẩm',
                        ProductSimilar: 'Sản phẩm tương tự',
                        Total: 'Tổng',
                        Buy: 'Mua',
                        ProductTop: 'Sản phẩm bán chạy',
                        JV: 'Sản phẩm vừa xem',
                        Price: 'Giá',
                        NumberDiscount: 'Lượt giảm giá',
                        NumberReview: 'Lượt review',
                        Review: 'Đánh giá',
                        Name: 'Tên sản phẩm',
                        Image: 'Ảnh sản phẩm',
                        Status: 'Trạng thái',
                        NonNegativePrice: 'Giá phải không âm',
                        NonNegativeQuantity: 'Số lượng phải không âm',
                        ProductDetail: 'Chi tiết sản phẩm',
                        EnterColor: 'Nhập màu',
                        EnterSize: 'Nhập kích thước',
                        ShopManagement: {
                            ProductManagement: 'Quản lý sản phẩm',
                            ViewProductDetail: 'Xem chi tiết sản phẩm',
                            ProductDetail: 'Chi tiết sản phẩm',
                            CreateProduct: 'Tạo sản phẩm mới',
                            CategorySelect: 'Chọn danh mục',
                            MaterialSelect: 'Chọn chất liệu',
                            StyleSelect: 'Chọn phong cách',
                            OriginSelect: 'Chọn xuất xứ',
                            BrandSelect: 'Chọn thương hiệu',
                            CreateProductForm: 'Biểu mẫu tạo sản phẩm mới',
                            ProductList: 'Danh sách sản phẩm của cửa hàng',
                            ConfirmToDeleteProduct: 'Xác nhận xóa sản phẩm này',
                            DeleteProduct: 'Xóa sản phẩm',
                            FormEditProduct: 'Biểu mẫu chỉnh sửa sản phẩm',
                            EditProduct: 'Chỉnh sửa sản phẩm',
                        },
                        InappropriateNameOrImage: 'Tên hoặc hình ảnh không phù hợp',
                        SensitiveProduct: 'Sản phẩm nhạy cảm',
                        NotAFashionProduct: 'Không phải sản phẩm thời trang',
                    },
                    category: {
                        Categorys: 'Tất cả danh mục',
                        Sale: 'Giảm giá',
                        HightToLow: 'Giá cao đến thấp',
                        LowToHight: 'Giá thấp đến cao',
                        NumberSold: 'Số lượng bán',
                        Sort: 'Sắp xếp',
                        Filter: 'Lọc',
                        Detail: 'Chi tiết danh mục',
                        CategoryName: 'Tên danh mục',
                        ParentCategoryId: 'Id danh mục cha',
                        CategoryImage: 'Hình ảnh',
                        ParentCategoryLv1: 'Danh mục cha cấp 1',
                        ParentCategoryLv2: 'Danh mục cha cấp 2',
                        ParentCategoryLv3: 'Danh mục cha cấp 3',
                        Admin: {
                            CategoryManagement: 'Quản lý danh mục',
                            CreateCategory: 'Tạo danh mục mới',
                            CreateOrigin: 'Tạo xuất xứ mới',
                            CreateBrand: 'Tạo thương hiệu mới',
                            CreateMaterial: 'Tạo chất liệu mới',
                            CreateStyles: 'Tạo kiểu dáng',
                            CategoryList: 'Danh sách danh mục',
                            Actions: 'Thao tác',
                            BulkActions: 'Thao tác hàng loạt',
                            ConfirmToDeleteCategory: 'Xác nhận xóa danh mục này',
                            ConfirmToBanShop: 'Xác nhận cấm shop này',
                            DeleteCategory: 'Xóa danh mục',
                            FormEditCategory: 'Biểu mẫu chỉnh sửa danh mục',
                            EditCategory: 'Chỉnh sửa danh mục',
                            DetailCategory: 'Chi tiết danh mục',
                            BanShop: 'Cấm cửa hàng',
                            UnBanShop: 'Gỡ cấm cửa hàng',
                            ConfirmToUnBanShop: 'Xác nhận gỡ cấm shop này',
                            ShopManagement: 'Quản lý shop',
                            ShopList: 'Danh sách shop',
                            RequestWithdraw: 'Yêu cầu rút tiền',
                            RequestWithdrawList: 'Danh sách yêu cầu',
                            ConfirmToAcceptWithdraw: 'Xác nhận chuyển tiền',
                            ConfirmToCancelWithdraw: 'Hủy yêu cầu',
                            ConfirmToCancelReportShop: 'Bỏ qua báo cáo này',
                            ConfirmToDecreasePoint: 'Xác nhận trừ điểm',
                            ConfirmToBanThisShop: 'Xác nhận cấm cửa hàng này',
                            ConfirmToBanUser: 'Xác nhận cấm người dùng này',
                            UserManagement: 'Quản lý người dùng',
                            UserList: 'Danh sách người dùng',
                            BanUser: 'Cấm người dùng',
                            UnBanUser: 'Gỡ cấm người dùng',
                            Decrease: 'Trừ điểm',
                            ReportShop: 'Khiếu nại shop',
                            List: 'Danh sách',
                            Transaction: 'Giao dịch',
                        },
                    },
                    order: {
                        Status: 'Trạng thái',
                        Total: 'Tổng tiền',
                        TotalAmount: 'Thành tiền',
                        Checkout: 'Thanh toán',
                        DeliveryAddress: 'Địa chỉ nhận hàng',
                        Province: 'Tỉnh/thành phố',
                        District: 'Quận/huyện',
                        Ward: 'Phường/xã',
                        Apartment: 'Địa chỉ',
                        PriceShip: 'Giá vận chuyển',
                        Member: 'Thành viên',
                        DeliveredMethod: 'Phương thức giao hàng',
                        PaymentMethod: 'Phương thức thanh toán',
                        CashOnDelivery: 'Thanh toán khi nhận hàng',
                        Estimated: 'Dự kiến',
                        Processing: 'Đang xử lý',
                        Processed: 'Đã giao',
                        Confirmed: 'Đã xác nhận',
                        Delivering: 'Đang vận chuyển',
                        Cancel: 'Đã hủy',
                        Refunded: 'Đã hoàn tiền',
                        Code: 'Mã đơn hàng',
                        NotPaid: 'Chưa thanh toán',
                        Paid: 'Đã thanh toán',
                        PleaseWait: 'Vui lòng chờ !!',
                        SendReview: 'Gửi đánh giá',
                        SendReport: 'Báo cáo',
                        Reviewed: 'Đã đánh giá',
                        Product: 'Sản phẩm',
                        Detail: 'Chi tiết đơn hàng',
                        ConfirmAction: 'Xác nhận đơn hàng',
                        ConfirmOrder: 'Xác nhận đơn hàng, đơn hàng chờ vận chuyển',
                        ConfirmDelivering: 'Cập nhật trạng thái của đơn hàng này sang hàng đang được vận chuyển',
                        ConfirmDelivered: 'Xác nhận đơn hàng đã giao thành công',
                        ProductIsInCorrect: 'Sản phẩm không đúng',
                        ProductHasNotBeenDelivered: 'Chưa nhận được hàng',
                        SendedReport: 'Đã gửi báo báo',
                        AddShopVoucher: 'Thêm voucher của shop',
                        Using: 'Áp dụng',
                        RequestRefund: 'Yêu cầu hoàn trả đơn',
                    },
                    notification: {
                        Notifications: 'Thông Báo',
                        UNREAD: 'Chưa đọc',
                        SEEN: 'Đã xem',
                        All: 'Tất cả',
                        More: 'Xem thêm',
                        Hide: 'Ẩn',
                        NoNotification: 'Không có thông báo',
                    },
                    voucher: {
                        Expired: 'Đã hết hạn',
                        Id: 'Id voucher',
                        Name: 'Tên voucher',
                        Code: 'Mã voucher',
                        ExpireDate: 'Ngày hết hạn',
                        Quantity: 'Số lượng mã',
                        Reduce: 'Tiền giảm',
                        Condition: 'Điều kiện giảm',
                        VoucherManagement: 'Quản lý voucher',
                        CreateVoucher: 'Tạo voucher mới',
                        CreateVoucherForm: 'Biểu mẫu tạo voucher mới',
                        VoucherList: 'Danh sách voucher',
                        ConfirmToDeleteVoucher: 'Xác nhận xóa voucher này',
                        ConfirmToDeleteTheseVouchers: 'Xác nhận xóa các voucher này',
                        DeleteVoucher: 'Xóa voucher',
                        EditVoucherForm: 'Biểu mẫu chỉnh sửa voucher',
                        EditVoucher: 'Chỉnh sửa voucher',
                    },
                    discount: {
                        Expired: 'Đã hết hạn',
                        Name: 'Tên giảm giá',
                        Percent: 'Phần trăm giảm giá',
                        Id: 'Id giảm giá',
                        ExpireDate: 'Ngày hết hạn',
                        DiscountManagement: 'Quản lý giảm giá',
                        DiscountList: 'Danh sách giảm giá',
                        ConfirmToDeleteDiscount: 'Xác nhận xóa giảm giá này',
                        DeleteDiscount: 'Xóa giảm giá',
                        EditDiscountForm: 'Biểu mẫu chỉnh sửa giảm giá',
                        EditDiscount: 'Chỉnh sửa giảm giá',
                    },
                    reportProduct: {
                        ProductName: 'Tên sản phẩm',
                        ProductImage: 'Ảnh sản phẩm',
                        Describe: 'Mô tả',
                        Check: 'Trạng thái',
                        ReportManagement: 'Quản lý báo cáo sản phẩm',
                        ReportList: 'Danh sách báo cáo sản phẩm',
                        ConfirmToCheckReport: 'Xác nhận đã kiểm tra sản phẩm này',
                        CheckReport: 'Kiểm tra sản phẩm',
                    },
                    banner: {
                        Image: 'Ảnh quảng cáo',
                        Expired: 'Ngày hết hạn',
                        ProductId: 'Id sản phẩm quảng cáo',
                        NoRequire: 'Không bắt buộc',
                        Link: 'Đường dẫn quảng cáo',
                        Level: 'Hạng',
                        BannerCost: 'Phí quảng cáo',
                        BannerManagement: 'Đăng ký quảng cáo',
                        CreateBanner: 'Đăng ký quảng cáo mới',
                        CreateBannerForm: 'Biểu mẫu đăng ký quảng cáo',
                        BannerList: 'Danh sách quảng cáo',
                        ConfirmToCancelBanner: 'Xác nhận hủy đăng ký quảng cáo này',
                        CancelBanner: 'Hủy quảng cáo',
                    },
                    reportOrder: {
                        Image: 'Ảnh báo cáo đơn hàng',
                        Expired: 'Ngày hết hạn',
                        ShopReason: 'Lý do từ chối',
                        ShopImage: 'Ảnh minh chứng',
                        Describe: 'Mô tả',
                        Link: 'Đường dẫn sản phẩm',
                        Check: 'Trạng thái',
                        Info: 'Chi tiết khiếu nại',
                        RefundAndBanShop: 'Hoàn trả và cấm cửa hàng',
                        ReportOrderManagement: 'Hoàn/Trả đơn hàng',
                        ReportOrderList: 'Danh sách báo cáo đơn hàng',
                        ConfirmToAcceptRefundOrder: 'Xác nhận hoàn trả đơn hàng này',
                        ConfirmToCancelReportOrder: 'Xác nhận hủy báo cáo đơn hàng này',
                        ConfirmToAcceptRefundOrderAndBanShop: 'Xác nhận hoàn trả đơn hàng này và cấm cửa hàng',
                        RejectReportOrder: 'Từ chối hoàn trả đơn hàng',
                        WarningRejectRefund:
                            'Cảnh báo! Sau khi bạn từ chối hoàn tiền, yêu cầu này sẽ đc gửi về quản trị viên và cửa hàng của bạn có thể bị cấm',
                        RefundOrder: 'Hoàn trả đơn hàng',
                        ComplaintReportOrder: 'Khiếu nại hoàn trả đơn',
                        NoImage: 'Không có',
                    },
                    orderRevenue: {
                        OrderRevenueList: 'Danh sách đơn hàng đã giao của shop',
                        OrderRevenueManagement: 'Doanh thu đơn hàng',
                        TotalAmount: 'Tổng tiền đơn hàng',
                        TransactionFee: 'Phí giao dịch sàn',
                        Revenue: 'Doanh thu',
                        VoucherReduce: 'Giá giảm từ voucher',
                        TotalRevenue: 'Tổng doanh thu tháng',
                        OrderCount: 'Số lượng đơn hàng',
                        TotalRevenueDelivering: 'Doanh thu đơn đang giao',
                        OrderCountDelivering: 'Số đơn đang giao',
                    },
                    shopDashboard: {
                        ShopOverview: 'Tổng quan cửa hàng',
                        OrderCount: 'Số Đơn Hàng',
                        Revenue: 'Doanh Thu',
                        TotalRevenue: 'Tổng Doanh Thu',
                        TopProduct: 'Sản Phẩm Bán Chạy',
                        TotalRevenueUnPaid: 'Chưa Thanh Toán',
                        TotalOrder: 'Tổng Số Đơn Hàng',
                        TotalOrderDelivering: 'Số đơn đang giao',
                        TotalProductSold: 'Số Sản Phẩm Đã Bán',
                        OrderCountByDay: 'Số Đơn Hàng Theo Ngày',
                        RevenueByDay: 'Doanh Thu Theo Ngày',
                        Month: 'Tháng',
                        Year: 'Năm',
                        RevenuesAndOrderStatistics: 'Thống kê doanh thu và số đơn hàng',
                    },
                    adminDashboard: {
                        OrderCount: 'Số Đơn Hàng',
                        OrderCountByDay: 'Số Đơn Hàng Theo Ngày',
                        TotalNewShops: 'Số Cửa Hàng Mới',
                        TotalNewUsers: 'Số Người Dùng Mới',
                        AdminOverview: 'Tổng Quan Hệ Thống',
                        OrderCommission: 'Hoa Hồng Đơn Hàng',
                        BannerCommission: 'Hoa Hồng Quảng Cáo',
                        TotalRevenue: 'Tổng Doanh Thu',
                        TotalOrders: 'Số Đơn Hàng',
                        TotalShops: 'Số Lượng Cửa Hàng',
                        TotalUsers: 'Số Lượng Người Dùng',
                        OrderCommissionByDay: 'Hoa Hồng Đơn Hàng Theo Ngày',
                        BannerCommissionByDay: 'Hoa Hồng Quảng Cáo Theo Ngày',
                        Month: 'Tháng',
                        Year: 'Năm',
                        ThisMonth: 'So với tháng trước',
                    },
                    shopProfile: {
                        EditInfoForm: 'Biểu mẫu chỉnh sửa thông tin cửa hàng',
                        EditInfo: 'Chỉnh sửa thông tin cửa hàng',
                        Name: 'Tên cửa hàng',
                        Phone: 'Số điện thoại',
                        Point: 'Điểm của cửa hàng',
                        Status: 'Trạng thái cửa hàng',
                        Address: 'Địa chỉ cửa hàng',
                        Describe: 'Mô tả cửa hàng',
                        ShopInfo: 'Thông tin cửa hàng',
                        Orders: 'Đơn hàng',
                        Total: 'Tổng cộng',
                        Failed: 'Thất bại',
                        Follows: 'Lượt theo dõi',
                        Users: 'Người dùng',
                        Reviews: 'Lượt đánh giá',
                        RecentActivity: 'Hoạt động gần đây',
                    },
                    shopMenuSidebar: {
                        Management: 'Quản lý cửa hàng',
                        Product: 'Sản phẩm',
                        Order: 'Đơn hàng',
                        OrderRevenue: 'Doanh thu đơn hàng',
                        Discount: 'Giảm giá',
                        Voucher: 'Voucher giảm giá',
                        ReportProduct: 'Báo cáo sản phẩm',
                        Banner: 'Đăng ký quảng cáo',
                        ReportOrder: 'Đơn hoàn/trả',
                    },
                    orther: {
                        Other: 'Khác',
                        AllStepCompleted: 'Tất cả các bước đã hoàn tất',
                        Locked: 'Đóng băng',
                        Role: 'Quyền',
                        Link: 'Đường dẫn',
                        Hello: 'Xin chào',
                        None: 'Không có',
                        Step: 'Bước',
                        All: 'Tất cả',
                        Complete: 'Hoàn thànhthành',
                        Profile: 'Thông tin',
                        Wallet: 'Ví cá nhân',
                        Chats: 'Đoạn chat',
                        Month: 'Tháng',
                        Year: 'Năm',
                        ReturnHome: 'Quay lại trang chủ'
                    },
                    footer: {
                        Contact: 'Thông tin liên hệ',
                        Services: 'Dịch vụ',
                        AboutUs: 'About us',
                        Payment: 'Thanh toán',
                    },
                    toast: {
                        Success: 'Thành công',
                        Fail: 'Thất bại',
                        EditSuccess: 'Chỉnh sửa thành công',
                        EditFail: 'Chỉnh sửa thất bại',
                        CreateSuccess: 'Thêm thành công',
                        CreateFail: 'Thêm thất bại',
                        DeleteSuccess: 'Xóa thành công',
                        DeleteFail: 'Xóa thất bại',
                        NotEnoughMoneyInWallet: 'Không đủ tiền trong ví của bạn',
                        CreateProductCondition: 'Bạn cần thêm ít nhất một màu và ít nhất một kích thước',
                        NeedProductImage: 'Phải tải lên ít nhất một ảnh sản phẩm',
                        NoDiscount: 'Sản phẩm không có giảm giá',
                        VoucherIsNoLonger: 'Voucher không còn được sử dụng',
                        VoucherHasUsed: 'Bạn đã sử dụng voucher này',
                        NotEnoughCondition: 'Không đủ điều kiện',
                        ProductIdMustBeExactly24Characters: 'Id sản phẩm phải có chính xác 24 ký tự',
                        VoucherOutOfStock: 'Voucher hết số lượng',
                        VoucherNotFound: 'Không tìm thấy voucher',
                        NeedCreateWalletFirst: 'Để thực hiện chức năng này cần tạo ví trước',
                        ReportedShop: 'Đã báo cáo cửa hàng',
                        ProductIdNotFound: 'Không tìm thấy id sản phẩm',
                        NeedAtleastOneColorOneSize: 'Bạn cần thêm ít nhất một màu và ít nhất một kích thước',
                        ReductConditionQuantityNonNegative: 'Số tiền giảm, điều kiện giảm, và số lượng phải không âm',
                        VoucherCodeAlreadyExists: 'Voucher code này đã tồn tại',
                        ConditionExpireDate: 'Ngày hết hạn phải là thời điểm sau thời điểm hiện tại',
                        SelectExpireDate: 'Vui lòng chọn ngày hết hạn',
                        CantBuyOwnProduct: 'Không thể tự mua sản phẩm',
                        PleaseWait: 'Vui lòng chờ',
                        OTPExpired: 'OTP hết hạn',
                        InvalidEmailFormat: 'Email không hợp lệ',
                        Banned: 'Bạn đã bị cấm',
                        MissingAddress: 'Thiếu địa chỉ, vui lòng cài đặt',
                        NotEnoughBalance: 'Không đủ số dư, vui lòng nạp thêm',
                        ShopBanned: 'Cửa hàng đã bị cấm',
                        NotEnoughQuantity: 'Không đủ số lượng',
                        NameAlreadyExists: 'Tên này đã tồn tại',
                        PleaseRegisterWallet: 'Đăng kí ví trước khi dùng chức năng này',
                    },
                    action: {
                        Ban: 'Cấm',
                        UnBan: 'Gỡ cấm',
                        EnterID: 'Nhập vào ID',
                        EnterName: 'Nhập vào tên',
                        EnterEmail: 'Nhập vào email',
                        ChatNow: 'Chat ngay',
                        ViewShop: 'Xem shop',
                        SeeMore: 'Xem thêm',
                        Collapse: 'Thu gọn',
                        Confirm: 'Xác nhận',
                        Cancel: 'Hủy',
                        Refund: 'Hoàn tiền',
                        Actions: 'Thao tác',
                        Close: 'Đóng',
                        BulkActions: 'Thao tác hàng loạt',
                        Save: 'Lưu',
                        SignOut: 'Đăng xuất',
                        ChangeImage: 'Thay đổi ảnh',
                        Delete: 'Xóa',
                        Edit: 'Chỉnh sửa',
                        Check: 'Đã kiểm tra',
                        Detail: 'Xem thông tin',
                        Accept: 'Chấp nhận',
                        Reject: 'Từ chối',
                        Send: 'Gửi',
                        SelectOneToStartMesage: 'CHỌN MỘT ĐỂ BẮT ĐẦU NHẮN TIN',
                        ApplyAll: 'Áp dụng cho tất cả',
                        DeleteAll: 'Xóa tất cả',
                        Back: 'Quay lại'
                    },
                    admin: {
                        Shop: 'Cửa hàng',
                        User: 'Người dùng',
                        Category: 'Danh mục',
                        Withdraw: 'Yêu cầu rút tiền',
                        ReportShop: 'Báo cáo shop',
                        NumberSale: 'Đã bán',
                        NumberOrder: 'Số lượng order',
                        Point: 'Điểm số',
                        Follow: 'Lượt Follow',
                        Manage: 'Quản lý',
                        ReportOrder: 'Khiếu nại hoàn đơn',
                    },
                },
            },
            en: {
                translation: {
                    auth: {
                        Login: 'Log in',
                        Register: 'Register',
                        OrLoginWith: 'Or log in with',
                        Phone: 'Phone',
                        Password: 'Password',
                        'Please enter complete information': 'Please enter complete information',
                        'Account is incorrect': 'Account is incorrect',
                        'Enter OTP': 'Enter OTP',
                        Submit: 'Submit',
                        Cancel: 'Cancel',
                        'Verify failed': 'Verify failed',
                        'Verify success': 'Verify success',
                        'Can not send OTP': 'Can not send OTP',
                        'Send OTP successfully': 'Send OTP successfully',
                        'Forget password': 'Forget password',
                        'Enter phone': 'Enter phone',
                        'Phone non exist': 'Phone non exist',
                        Success: 'Success',
                        'Can not change password': 'Can not change password',
                        Change: 'Change',
                        'Re-password': 'Re-password',
                        'Password must contain letters, numbers, one capital letter, one special character and must be 10 characters or more':
                            'Password must contain letters, numbers, one capital letter, one special character and must be 10 characters or more',
                        'Password is no strong': 'Password is no strong',
                        'Password and Re-password do not match': 'Password and Re-password do not match',
                        'Hello, Friend': 'Hello, Friend',
                        'Become a member to receive many attractive offers':
                            'Become a member to receive many attractive offers',
                        'Account is inActive': 'Account is inActive',
                        'Welcome Back': 'Welcome Back',
                        'To login please enter your personal information':
                            'To login please enter your personal information',
                        'Account have already exist': 'Account have already exist',
                        'Enter Email': 'Enter Email',
                        'Can not find user': 'Can not find user',
                        Back: 'Back',
                        IncorrectPassword: 'Incorrect Password',
                    },
                    homepage: {
                        'Free returns within 30 days': 'Free returns within 30 days',
                        Exit: 'Exit',
                        'Hot key': 'Hot key',
                        'Product suggestions': 'Product suggestions',
                        'New Product': 'New Product',
                        Sale: 'Sale',
                        Category: 'Category',
                        'Men Fashion': 'Men Fashion',
                        'Women Fashion': 'Women Fashion',
                    },
                    user: {
                        Profile: 'Profile',
                        Orders: 'Orders',
                        Logout: 'Logout',
                        Setting: 'Setting',
                        'Info Profile': 'Info Profile',
                        Name: 'Name',
                        Birthday: 'Birthday',
                        Sex: 'Sex',
                        Male: 'Male',
                        Female: 'Female',
                        Phone: 'Phone',
                        Address: 'Address',
                        Secure: 'Secure',
                        Notification: 'Notification',
                        Shop: 'Shop',
                        AllAddress: 'All Address',
                        CreateAddress: 'Create Address',
                        City: 'City',
                        District: 'District',
                        Ward: 'Ward',
                        ApartmentNumber: 'Apartment Number',
                        Save: 'Save',
                        Default: 'Default',
                        EditAddress: 'Edit Address',
                        InfoNotSave: 'There is unsaved data',
                        InfoShop: 'Info Shop',
                        DefaultAddress: 'Default address',
                        Wallet: 'Wallet',
                        RegisterWallet: 'Register Wallet',
                        Code: 'Code',
                        Describe: 'Describe',
                        Time: 'Time',
                        Value: 'Value',
                        Balance: 'Balance',
                        Deposite: 'Deposite',
                        Withdraw: 'Withdraw',
                        MethodBank: 'Method Bank',
                        NameBank: 'Name Bank',
                        NumberAccount: 'Number Account',
                        NameAccount: ' Name Account',
                        EnterNumber: 'Enter Number',
                        History: 'History',
                        Pending: 'Pending',
                        'There is a transaction being processed': 'There is a transaction being processed',
                        NotEnoughBalance: 'Not Enough Balance',
                        PleaseEnterNumber: 'Please Enter Number',
                        YourVoucher: 'Your voucher',
                    },
                    shop: {
                        Name: 'Shop Name',
                        Address: 'Shop Address',
                        Phone: 'Phone',
                        ShopNameAlreadyExits: 'Shop name already exits',
                        Followed: 'Followed',
                        TopProduct: 'Top Products',
                        StartDay: 'Start Day',
                        Condition: 'Condition',
                        Expery: 'Expery',
                    },
                    product: {
                        Id: 'Product id',
                        Color: 'Color',
                        Size: 'Size',
                        ColorList: 'Color list',
                        SizeList: 'Size list',
                        ProductOptionList: 'Product options list',
                        NumberSold: 'Number Sold',
                        QuantityProduct: 'Quantity',
                        Quantity: 'Quantity',
                        AddToCart: 'Add To Cart',
                        BuyNow: 'Buy Now',
                        SizeTip: 'Size Tips',
                        Describe: 'Describe',
                        Info: 'Info',
                        PercentDisCountTop: 'Percent discount top',
                        PercentDisCount: 'Percent discount',
                        Category: 'Category',
                        Material: 'Material',
                        Brand: 'Brand',
                        Styles: 'Styles',
                        Origin: 'Origin',
                        Shop: 'Shop',
                        Sold: 'Sold',
                        Product: 'Product',
                        ProductSimilar: 'Product Similar',
                        Total: 'Total',
                        Buy: 'Buy',
                        ProductTop: 'ProductTop',
                        JV: 'Product Just View',
                        Price: 'Price',
                        NumberDiscount: 'Number Discount',
                        NumberReview: 'Number review',
                        Review: 'Review',
                        Name: 'Product name',
                        Image: 'Product image',
                        Status: 'Status',
                        NonNegativePrice: 'Non-negative price',
                        NonNegativeQuantity: 'Non-negative quantity',
                        ProductDetail: 'Product detail',
                        EnterColor: 'Enter color',
                        EnterSize: 'Enter size',
                        ShopManagement: {
                            ViewProductDetail: 'View product details',
                            ProductDetail: 'Product details',
                            ProductManagement: 'Product ManageMent',
                            CreateProduct: 'Create a new product',
                            CategorySelect: 'Select category',
                            MaterialSelect: 'Select material',
                            StyleSelect: 'Select style',
                            OriginSelect: 'Select origin',
                            BrandSelect: 'Select brand',
                            CreateProductForm: 'Form create a new product',
                            ProductList: 'List of shop products',
                            ConfirmToDeleteProduct: 'Confirm to delete this product',
                            DeleteProduct: 'Delete product',
                            FormEditProduct: 'Edit product form',
                            EditProduct: 'Edit product',
                            ConfirmToBanShop: 'Confirm to ban this shop',
                            UnBanShop: 'Un ban shop',
                            ConfirmToUnBanShop: 'Confirm to un ban this shop',
                        },
                        InappropriateNameOrImage: 'Inappropriate Name Or Image',
                        SensitiveProduct: 'Sensitive product',
                        NotAFashionProduct: 'Not a fashion product',
                    },
                    category: {
                        Categorys: 'Categories',
                        Sale: 'Sale',
                        HightToLow: 'Hight To Low',
                        LowToHight: 'Low To Hight',
                        NumberSold: 'Number Sold',
                        Sort: 'Sort',
                        Filter: 'Filter',
                        Detail: 'Category details',
                        CategoryName: 'Category name',
                        ParentCategoryId: 'Parent category id',
                        CategoryImage: 'Image',
                        ParentCategoryLv1: 'Parent Category Level 1',
                        ParentCategoryLv2: 'Parent Category Level 2',
                        ParentCategoryLv3: 'Parent Category Level 3',
                        Admin: {
                            CategoryManagement: 'Category management',
                            CreateCategory: 'Create a new category',
                            CreateOrigin: 'Create a new origin',
                            CreateBrand: 'Create a new brand',
                            CreateMaterial: 'Create a new material',
                            CreateStyles: 'Create a new style',
                            CategoryList: 'List of categories',
                            Actions: 'Actions',
                            BulkActions: 'Bulk actions',
                            ConfirmToDeleteCategory: 'Confirm deletion of this category',
                            DeleteCategory: 'Delete category',
                            FormEditCategory: 'Category Edit Form',
                            EditCategory: 'Edit category',
                            DetailCategory: 'Chi tiết danh mục',
                            BanShop: 'Ban shop',
                            ShopManagement: 'Shop management',
                            ShopList: 'Shop list',
                            RequestWithdraw: 'Request Withdraw',
                            RequestWithdrawList: 'Request Withdraw List',
                            ConfirmToAcceptWithdraw: 'Confirm to accept this request',
                            ConfirmToCancelWithdraw: 'Confirm to cancel this request',
                            ConfirmToBanShop: 'Confirm to ban this shop',
                            ConfirmToUnBanShop: 'Confirm to un ban this shop',
                            ConfirmToCancelReportShop: 'Confirm to cancel this report',
                            ConfirmToDecreasePoint: 'Accept decrease point this shop',
                            ConfirmToBanThisShop: 'Accept to ban this shop',
                            ConfirmToBanUser: 'Accept to ban this user',
                            UserManagement: 'User management',
                            UserList: 'User List',
                            BanUser: 'Ban user',
                            UnBanUser: 'Unban user',
                            Decrease: 'Decrease',
                            ReportShop: 'Report Shop',
                            List: 'List',
                            Transaction: 'Transaction',
                        },
                    },
                    order: {
                        Status: 'Status',
                        Total: 'Total',
                        TotalAmount: 'Total Amount',
                        Checkout: 'Checkout',
                        DeliveryAddress: 'Delivery Address',
                        PriceShip: 'Price Ship',
                        Member: 'Member',
                        DeliveredMethod: 'Delivered Method',
                        PaymentMethod: 'Payment Method',
                        CashOnDelivery: 'Cash On Delivery',
                        Estimated: 'Estimated',
                        Processing: 'Processing',
                        Processed: 'Processed',
                        Confirmed: 'Confirmed',
                        Delivering: 'Delivering',
                        Cancel: 'Cancel',
                        Refunded: 'Refunded',
                        Code: 'Code',
                        NotPaid: 'Not Paid',
                        Paid: 'Paid',
                        PleaseWait: 'Please wait !!',
                        SendReview: 'Send Review',
                        SendReport: 'Send Report',
                        Reviewed: 'Reviewed',
                        Product: 'Product',
                        Detail: 'Order detail',
                        ConfirmAction: 'Confirm order',
                        ConfirmDelivering: 'Update this order status to DELIVERING',
                        ConfirmDelivered: 'Confirm order delivered successfully',
                        ProductIsInCorrect: 'Product Is InCorrect',
                        ProductHasNotBeenDelivered: 'Product Has Not Been Delivered',
                        SendedReport: 'Reported',
                        AddShopVoucher: 'Add Shop Voucher',
                        Using: 'Using',
                        RequestRefund: 'Request to refund/return order',
                    },
                    notification: {
                        Notifications: 'Notifications',
                        UNREAD: 'UNREAD',
                        SEEN: 'SEEN',
                        All: 'All',
                        More: 'More',
                        Hide: 'Hide',
                        NoNotification: 'No notifications',
                    },
                    voucher: {
                        Expired: 'Expired',
                        Id: 'Voucher id',
                        Name: 'Voucher name',
                        Code: 'Voucher code',
                        ExpireDate: 'Expire Date',
                        Quantity: 'Voucher quantity',
                        Reduce: 'Money reduction',
                        Condition: 'Condition',
                        VoucherManagement: 'Voucher management',
                        CreateVoucher: 'Create a new voucher',
                        CreateVoucherForm: 'Create voucher form',
                        VoucherList: 'Voucher list',
                        ConfirmToDeleteVoucher: 'Confirm to delete this voucher',
                        ConfirmToDeleteTheseVouchers: 'Confirm deletion of these vouchers',
                        DeleteVoucher: 'Delte voucher',
                        EditVoucherForm: 'Edit voucher form',
                        EditVoucher: 'Edit voucher',
                    },
                    discount: {
                        Expired: 'Expired',
                        Name: 'Discount name',
                        Percent: 'Discount percent',
                        Id: 'Discount id',
                        ExpireDate: 'Expire date',
                        DiscountManagement: 'Discount management',
                        DiscountList: 'Discount list',
                        ConfirmToDeleteDiscount: 'Confirm to delete this discount',
                        DeleteDiscount: 'Delete discount',
                        EditDiscountForm: 'Edit discount form',
                        EditDiscount: 'Edit discount',
                    },
                    reportProduct: {
                        ProductName: 'Product name',
                        ProductImage: 'Product Image',
                        Describe: 'Describe',
                        Check: 'Status',
                        ReportManagement: 'Report management',
                        ReportList: 'Report list',
                        ConfirmToCheckReport: 'Confirmed that this product has been checked',
                        CheckReport: 'Check product',
                    },
                    banner: {
                        Image: 'Banner Image',
                        Expired: 'Expired date',
                        Level: 'Level banner',
                        Check: 'Status',
                        Link: 'Banner link',
                        ProductId: 'Product id',
                        NoRequire: 'No require',
                        BannerCost: 'Banner cost',
                        BannerManagement: 'Banner management',
                        CreateBanner: 'Sign up a banner',
                        CreateBannerForm: 'Sign up banner form',
                        BannerList: 'Banner list',
                        ConfirmToCancelBanner: 'Confirm to cancel this banner',
                        CancelBanner: 'Cancel banner',
                    },
                    reportOrder: {
                        Image: 'Report Order Image',
                        ShopReason: 'Reason for rejection',
                        ShopImage: 'Illustrative image',
                        Expired: 'Expired date',
                        Check: 'Status',
                        Info: 'Detail',
                        RefundAndBanShop: 'Refund and ban shop',
                        Describe: 'Describe',
                        ReportOrderManagement: 'Refund/return order',
                        ReportOrderList: 'Report Order list',
                        ConfirmToAcceptRefundOrder: 'Confirm to refund this order',
                        ConfirmToCancelReportOrder: 'Confirm to cancel this order',
                        ConfirmToAcceptRefundOrderAndBanShop: 'Confirm to refund this order and ban this shop',
                        RejectReportOrder: 'Refund report order',
                        WarningRejectRefund:
                            'Warning! After you reject a refund, this request will be sent to the administrator and your store may be banned',
                        RefundOrder: 'Refund order',
                        ComplaintReportOrder: 'Complaint refund order',
                        NoImage: 'No image',
                    },
                    orderRevenue: {
                        OrderRevenueList: `List of delivered orders of the shop`,
                        OrderRevenueManagement: 'Order revenue',
                        TotalAmount: 'Total order amount',
                        TransactionFee: 'Transaction fee',
                        VoucherReduce: 'Price reduced from voucher',
                        Revenue: 'Revenue',
                        TotalRevenue: 'Total Revenue',
                        OrderCount: 'Order Count',
                        TotalRevenueDelivering: 'Total Revenue Delivering',
                        OrderCountDelivering: 'Order Count Delivering',
                    },
                    shopDashboard: {
                        ShopOverview: 'Shop Overview',
                        OrderCount: 'Order Count',
                        Revenue: 'Revenue',
                        TotalRevenue: 'Total Revenue',
                        TopProduct: 'Top Products',
                        TotalRevenueUnPaid: 'Unpaid',
                        TotalOrder: 'Total Orders',
                        TotalOrderDelivering: 'Total Order Delivering',
                        TotalProductSold: 'Total Products Sold',
                        OrderCountByDay: 'Order Count By Day',
                        RevenueByDay: 'Revenue By Day',
                        Month: 'Month',
                        Year: 'Year',
                        RevenuesAndOrderStatistics: 'Revenues and order statistics',
                    },
                    adminDashboard: {
                        OrderCount: 'Order Count',
                        OrderCountByDay: 'Order Count By Day',
                        TotalNewShops: 'Total New Shops',
                        TotalNewUsers: 'Total New Users',
                        AdminOverview: 'Admin Overview',
                        OrderCommission: 'Order Commission',
                        BannerCommission: 'Banner Commission',
                        TotalRevenue: 'Total Revenue',
                        TotalOrders: 'Total Orders',
                        TotalShops: 'Total Shops',
                        TotalUsers: 'Total Users',
                        OrderCommissionByDay: 'Order Commission By Day',
                        BannerCommissionByDay: 'Banner Commission By Day',
                        Month: 'Month',
                        Year: 'Year',
                        ThisMonth: 'Compared to last month',
                    },
                    shopProfile: {
                        EditInfoForm: 'Edit shop information form',
                        EditInfo: 'Edit information',
                        Name: 'Shop name',
                        Phone: 'Phone',
                        Address: 'Shop Address',
                        Status: 'Shop status',
                        Point: 'Shop point',
                        Describe: 'Shop description',
                        ShopInfo: 'Shop information',
                        Orders: 'Orders',
                        Total: 'Total',
                        Failed: 'Failed',
                        Follows: 'Follows',
                        Users: 'Users',
                        Reviews: 'Reviews',
                        RecentActivity: 'Recent Activity',
                    },
                    shopMenuSidebar: {
                        Management: 'Store Management',
                        Product: 'Product',
                        Order: 'Order',
                        OrderRevenue: 'Order revenue',
                        Discount: 'Discount',
                        Voucher: 'Discount Voucher',
                        ReportProduct: 'Product Report',
                        Banner: 'Advertisement Registration',
                        ReportOrder: 'Return/Refund Order',
                    },
                    orther: {
                        Other: 'Other',
                        Locked: 'Locked',
                        AllStepCompleted: 'All step completed',
                        Role: 'Role',
                        Link: 'Link',
                        Hello: 'Hello',
                        None: 'None',
                        Step: 'Step',
                        Complete: 'Complete',
                        All: 'All',
                        Profile: 'Shop Profile',
                        Wallet: 'E-Wallet',
                        Chats: 'Chats',
                        Month: 'Month',
                        Year: 'Year',
                        ReturnHome: 'Return to home'
                    },
                    footer: {
                        Contact: 'Contact Information',
                        Services: 'Services',
                        AboutUs: 'About us',
                        Payment: 'Payment',
                    },
                    action: {
                        Ban: 'Ban',
                        UnBan: 'Un ban',
                        EnterID: 'Enter ID',
                        EnterName: 'Enter name',
                        EnterEmail: 'Enter email',
                        Confirm: 'Confirm',
                        Cancel: 'Cancel',
                        Actions: 'Actions',
                        Close: 'Close',
                        Refund: 'Refund',
                        BulkActions: 'Bulk actions',
                        Save: 'Save',
                        ChangeImage: 'Change image',
                        Delete: 'Delete',
                        Edit: 'Edit',
                        Detail: 'Detail',
                        SignOut: 'Sign out',
                        ChatNow: 'Chat now',
                        ViewShop: 'View shop',
                        SeeMore: 'See more',
                        Collapse: 'Collapse',
                        Accept: 'Accept',
                        Reject: 'Reject',
                        Send: 'Send',
                        SelectOneToStartMesage: 'SELECT ONE TO START MESSAGE',
                        ApplyAll: 'Apply all',
                        DeleteAll: 'Delete all',
                        Back: 'BackBack'
                    },
                    toast: {
                        Success: 'Success',
                        Fail: 'Fail',
                        EditSuccess: 'Edit success',
                        EditFail: 'Edit fail',
                        CreateSuccess: 'Thêm success',
                        CreateFail: 'Create fail',
                        DeleteSuccess: 'Delete success',
                        DeleteFail: 'Delete fail',
                        NotEnoughMoneyInWallet: 'Not enough money in your wallet',
                        CreateProductCondition: 'You need to add at least one color and at least one size',
                        NeedProductImage: 'Must upload at least one product image',
                        NoDiscount: 'Product has no discount',
                        NotEnoughQuantity: 'Not enough quantity',
                        ShopBanned: 'Shop was banned',
                        NotEnoughBalance: 'Not enough balance, please add more',
                        MissingAddress: 'Missing address, please install',
                        Banned: 'You have been banned',
                        InvalidEmailFormat: 'Invalid email format',
                        OTPExpired: 'OTP expired',
                        PleaseWait: 'Please wait',
                        CantBuyOwnProduct: 'Cant buy your own products',
                        SelectExpireDate: 'Please select an expiration date',
                        ConditionExpireDate: 'Expiration date must be after the current time',
                        VoucherCodeAlreadyExists: 'This voucher code already exists',
                        ReductConditionQuantityNonNegative: 'Non-negative reduce, condition and quantity',
                        NeedAtleastOneColorOneSize: 'You need to add at least one color and at least one size',
                        ProductIdNotFound: 'Product id not found',
                        ReportedShop: 'Shop reported',
                        ProductIdMustBeExactly24Characters: 'Product id must be exactly 24 characters',
                        VoucherOutOfStock: 'Voucher out of stock',
                        VoucherNotFound: 'Voucher not found',
                        NeedCreateWalletFirst: 'To perform this function, a wallet must be created first',
                        NotEnoughCondition: 'Not enough condition',
                        VoucherHasUsed: 'You have used this voucher',
                        VoucherIsNoLonger: 'Voucher is no longer valid',
                        NameAlreadyExists: 'Name is already exists',
                        PleaseRegisterWallet: 'Please register wallet first',
                    },
                    admin: {
                        Shop: 'Shop',
                        User: 'User',
                        Category: 'Category',
                        Withdraw: 'Withdraw',
                        ReportShop: 'Report Shop',
                        NumberSale: 'Number sale',
                        NumberOrder: 'Number order',
                        Point: 'Point',
                        Follow: 'Follow',
                        Manage: 'Management',
                        ReportOrder: 'Complaint Report Order',
                    },
                },
            },
        },
        lng: lng,
        fallbackLng: typeLng.EN,
        interpolation: {
            escapeValue: false,
        },
    });
};
