import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductTop from '../../components/user-guest/product/ProductTop';
import Banner from '../../components/user-guest/Banner';
import MultiCaroselProductJV from '../../components/user-guest/product/MultiCaroselProductJV';
import Footer from '../../components/user-guest/footer/Footer';
import ProductNew from '../../components/user-guest/product/ProductNew';
import { GetGuestApi } from '../../untils/Api';
import { Button, Chip, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Height } from '@mui/icons-material';
import BannerShop from '../../components/user-guest/BannerShop';
import { HOST_BE } from '../../common/Common';

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = (props) => {
    const { t } = useTranslation();
    const [category, setCategory] = useState<any>(undefined);
    const [isGetStart, setIsGetStart] = useState<boolean>(false);
    const getDataCategory = async () => {
        const res = await GetGuestApi('/api/get-categories');
        if (res.data.message == 'Success') {
            setCategory(res.data.categories.slice(0, 8));
        }
    };
    useEffect(() => {
        getDataCategory();
    }, []);
    return (
        <>
            <div style={{ marginTop: 100 }} className="">
                <div style={{ height: 500 }} className="grid grid-cols-2 pl-0 xl:pl-12 pr-0 xl:pr-12 bannerTop">
                    {/* <div className="flex justify-center">
                        <div>
                            <Button  variant="outlined">
                                Get start
                            </Button>
                        </div>
                    </div>
                    <div className="flex justify-center">b</div> */}
                </div>

                {!isGetStart ? (
                    <div className="container">
                        {/* category */}
                        <div className="mt-12 select-none">
                            <Divider>
                                <Chip label="WELL COME" size="small" />
                            </Divider>
                        </div>
                        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
                            <div className="col-span-2 mt-12 flex justify-center">
                                <Banner />
                            </div>

                            <div className="col-span-2 mt-12">
                                {category && (
                                    <div className="grid grid-cols-2 lg:grid-cols-4 ">
                                        {category.map((category: any, index: number) => (
                                            <div
                                                className={`mt-4 flex justify-center ${
                                                    index > 3 ? 'hidden lg:flex' : ''
                                                }`}
                                            >
                                                <a
                                                    style={{ width: 100 }}
                                                    href={`/category-view/${category.id}`}
                                                    className="select-none hover:opacity-70 transition-all duration-500"
                                                >
                                                    <div key={category.id} className="flex justify-center">
                                                        <img
                                                            className="rounded-xl"
                                                            style={{ width: 100, height: 100, objectFit: 'cover', }}
                                                            src={category.image ? category.image.startsWith('uploads') ? `${HOST_BE}/${category.image}`: category.image : ''}
                                                        />
                                                    </div>
                                                    <div className="flex justify-center mt-3">{category.name}</div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* --------------- */}

                        <div className="mt-6">
                            <div className=" border-b border-gray-300 flex mt-6">
                                <div
                                    style={{
                                        borderBottomWidth: 3,
                                    }}
                                    className="font-bold text-2xl border-b border-solid  border-blue-500"
                                >
                                    {t('product.ProductTop')}
                                </div>
                            </div>
                            <ProductTop />
                        </div>
                        <div>
                            <BannerShop />
                        </div>
                        <div id="product-new-id" className="mt-12">
                            <div className=" border-b border-gray-300 flex mt-6">
                                <div
                                    style={{
                                        borderBottomWidth: 3,
                                    }}
                                    className="font-bold text-2xl border-b border-solid  border-blue-500"
                                >
                                    {t('homepage.New Product')}
                                </div>
                            </div>
                            <ProductNew />
                        </div>
                        <MultiCaroselProductJV />
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <Footer />
        </>
    );
};
export default HomePage;
