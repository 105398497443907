import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../components/ComponentsLogin';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert } from '../../components/alert/Alert';
import { useNavigate } from 'react-router-dom';
import { PostApi } from '../../untils/Api';
import { useSelector, useStore } from 'react-redux';
import { ReducerProps } from '../../reducers/ReducersProps';
import { filterInput, toastError, toastSuccess, toastWarning } from '../../untils/Logic';
import { HOST_BE, typeRole } from '../../common/Common';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';
import { Avatar, Button as Btn } from '@mui/material';
import axios from 'axios';
import { change_is_loading } from '../../reducers/Actions';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
interface RegisterShopProps {}
const RegisterShop: React.FC<RegisterShopProps> = (props) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const user = useSelector((state: ReducerProps) => state.user);
    const store = useStore();
    //
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [selectImage, setSelectImage] = useState<File | null>(null);

    //
    const handleRegisterShop = async () => {
        if (phone != '' && phone != '' && address != '' && selectImage) {
            store.dispatch(change_is_loading(true));
            //image
            const data = new FormData();
            if (selectImage) {
                const imageBlob = await fetch(URL.createObjectURL(selectImage)).then((response) => response.blob());
                data.append('file', imageBlob);
            }
            const res = await axios.post(`${HOST_BE}/user/upload-image-review`, data, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.data.message == 'Success') {
                //info
                const resRegisterShop = await PostApi('/user/register-shop', localStorage.getItem('token'), {
                    phoneShop: phone,
                    name: name,
                    addressShop: address,
                    image: res.data.path,
                });
                if (resRegisterShop.data.message == 'Shop name already exits') {
                    toastWarning(t('shop.ShopNameAlreadyExits'));
                }
                if (resRegisterShop.data.message == 'Success Shop Create') {
                    window.location.href = `/shop`;
                } else {
                }
            }
            store.dispatch(change_is_loading(false));
        } else {
            toastWarning(t('auth.Please enter complete information'));
        }
    };
    //
    const handleBackAddress = () => {
        if (name != '' || phone != '' || address != '') {
            Alert(() => {
                nav('/user/info-user');
            }, t('user.InfoNotSave'));
        } else {
            nav('/user/info-user');
        }
    };
    return (
        <div>
            <div style={{ marginTop: 120 }} className="flex justify-center">
                {user.role == typeRole.USER ? (
                    user.walletId != null ? (
                        <div
                            style={{
                                width: '70%',
                            }}
                            className="box-shadow p-6 rounded-lg"
                        >
                            <Button
                                style={{
                                    width: 40,
                                    height: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={handleBackAddress}
                            >
                                <ArrowBackIcon />
                            </Button>
                            <div className="font-bold mt-12 text-2xl">{t('user.InfoShop')}</div>
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
                                <div className="relative  pl-12 pt-3">
                                    {selectImage ? (
                                        <Avatar
                                            sx={{ width: 200, height: 200 }}
                                            src={URL.createObjectURL(selectImage)}
                                        />
                                    ) : (
                                        <Avatar sx={{ width: 200, height: 200 }} src={''} />
                                    )}
                                    <span
                                        style={{ left: 200 }}
                                        className={`absolute bottom-0  bg-gray-white flex justify-center items-center rounded-full w-[20px] h-[20px] cursor-pointer `}
                                    >
                                        <Btn component="label">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                                    stroke="black"
                                                    stroke-width="3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                                                    stroke="black"
                                                    stroke-width="3"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <VisuallyHiddenInput
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(e: any) => {
                                                    const file = e.target.files[0];
                                                    if (
                                                        file &&
                                                        (file.type === 'image/png' || file.type === 'image/jpeg')
                                                    ) {
                                                        setSelectImage(file);
                                                    } else {
                                                        toastWarning('Incorrect file type');
                                                    }
                                                }}
                                            />
                                        </Btn>
                                    </span>
                                </div>
                                <div className="mt-3 col-span-1 lg:col-span-2">
                                    <div className="ml-12 flex items-center  text-xl font-thin grid grid-cols-4">
                                        <div className="col-span-1">
                                            <div className="text-sm">{t('shop.Name')}* :</div>
                                        </div>
                                        <div className="col-span-3">
                                            <Input
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                                placeholder={t('shop.Name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-12 flex items-center text-xl font-thin grid grid-cols-4">
                                        <div className="col-span-1">
                                            <div className="text-sm">{t('shop.Address')}* :</div>
                                        </div>
                                        <div className="col-span-3">
                                            <Input
                                                value={address}
                                                onChange={(e) => {
                                                    setAddress(e.target.value);
                                                }}
                                                placeholder={t('shop.Address')}
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-12 flex items-center text-xl font-thin grid grid-cols-4">
                                        <div className="col-span-1">
                                            <div className="text-sm">{t('shop.Phone')}* :</div>
                                        </div>
                                        <div className="col-span-3">
                                            <Input
                                                value={phone}
                                                onChange={(e) => {
                                                    filterInput(e.target.value, setPhone);
                                                }}
                                                placeholder={t('shop.Phone')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-12 mt-12 flex justify-end text-xl font-thin">
                                <Button style={{ paddingTop: 4, paddingBottom: 4 }} onClick={handleRegisterShop}>
                                    {t('auth.Submit')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Button onClick={() => nav(`/user/wallet`)}>
                            {t('user.RegisterWallet')} &nbsp; <ArrowForwardIcon />
                        </Button>
                    )
                ) : (
                    <Button onClick={() => nav(`/shop/${user.shopId}`)}>
                        Shop &nbsp; <ArrowForwardIcon />
                    </Button>
                )}
            </div>
        </div>
    );
};
export default RegisterShop;
